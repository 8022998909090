import {Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useState, useEffect } from 'react';
import { useAppContext } from '../../context/context';

export default function AddCategory(props) {
    const { open, setOpen, category } = props;
    const { addCategory, editCategory } = useAppContext();

    const [value, setValue] = useState('');

    function handleClose() {
        setValue('');
        setOpen(false);
    }

    function onAcept() {
        if (!!category) {
            const newCategory = category;

            newCategory.name = value;

            editCategory(newCategory);
        } else {
            addCategory(value);
        }
        setValue('');
        handleClose();
    }

    useEffect(() => {
        if (!!category) {
            setValue(category.name);
        }
    }, [category]);

    return (
        <Dialog  open={open} onClose={handleClose}>
            <DialogTitle>{!!category ? 'Editar' : 'Nueva'} Categoria</DialogTitle>

            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Nombre"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={value}
                    onChange={(ev) => setValue(ev.target.value)}
                    
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={onAcept}>{!!category ? 'Editar' : 'Agregar'}</Button>
            </DialogActions>
        </Dialog>
    );
}