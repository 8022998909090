import { Divider, Stack, Typography , DialogActions, Paper, ToggleButtonGroup, ToggleButton, TextField, Box, Tabs, Tab, Button } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import DateAdapter from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { useState, useEffect } from 'react';
import { useAppContext } from '../../../context/context';

export const Ingreso_Types = {
	search: 'search',
	tatoo: 'tatoo',
	perfo: 'perfo',
	normal: 'normal',
	add: 'add'
}

const filterOptions = createFilterOptions({
	matchFrom: 'start',
	trim: true
});

function RowSearch(props) {
	return (
		<Stack>
			{props.name}
		</Stack>
	);
}

function Ingreso(props) {
	const { active } = props;
	const { products, emitView, userData, workers, actualUserAdmin, perfoPrice } = useAppContext();

	const [selectedValue, setSelectedValue] = useState(null);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [productList, setProductList] = useState([]);
	const [selectedWorkerValue, setSelectedWorkerValue] = useState(null);
	const [workerList, setWorkerList] = useState([]);
	const [type, setType] = useState(Ingreso_Types.search);
	const [canSend, setCanSend] = useState(false);

	const [description, setDescription] = useState('');
	const [creditCard, setCreditCard] = useState(false);
	const [cant, setCant] = useState('1');
	const [price, setPrice] = useState('0');
	const [amountValue, setAmountValue] = useState(0);

	function renderWorker() {
		if (type === Ingreso_Types.perfo || type === Ingreso_Types.tatoo) {
			return (
				<Stack sx={{mb:1, mt: 1}}>
					<Autocomplete
						blurOnSelect
						margin="normal"	
						fullWidth
						getOptionLabel={(option) => option.doc.nickName}
						filterOptions={filterOptions}
						options={workerList}
						value={selectedWorkerValue}
						label="Empleado"
						name="Empleado"
						filterSelectedOptions
						onChange={(ev, val) => {
							setSelectedWorkerValue(val);
						}}
						renderInput={(params) => <TextField {...params} label="Empleado" />}
					/>
				</Stack>
			);
		}

		return null;
	}

	function renderAddThings() {
		if (type === Ingreso_Types.add) {
			return (
				<Stack sx={{ mt: 1, mb: 1 }}>
					<Autocomplete
						blurOnSelect
						margin="normal"
						fullWidth
						getOptionLabel={option => option.doc.name}
						options={productList}
						value={selectedProduct}
						label="Producto"
						name="Producto"
						filterOptions={filterOptions}
						filterSelectedOptions
						onChange={(ev, val) => {
							setSelectedProduct(val);
						}}
						renderInput={(params) => <TextField {...params} label="Producto" />}
					/>

				<TextField
					margin="dense"
					id="name"
					label="Descripción"
					type="text"
					fullWidth
					value={description}
					onChange={e => setDescription(e.target.value)}
				/>
				</Stack>
			);
		}

		return null;
	}

	function renderCant() {
		if (type !== Ingreso_Types.tatoo && type !== Ingreso_Types.search) {
			return (
				<TextField
					margin="dense"
					id="amount"
					label="Cantidad"
					type="number"
					fullWidth
					value={cant}
					onChange={(ev) => setCant(ev.target.value)}
					InputProps={{
						inputMode: 'numeric',
						pattern: '[0-9]*'
					}}
				/>
			);
		}

		return null;
	}

	function handleClose() {
		setSelectedValue(null);
		setType(Ingreso_Types.search);
		setSelectedProduct(null);
		setDescription('');
		setSelectedWorkerValue(null);
		setCreditCard(false);
		setPrice('0');
	}

	function handleAcept() {
		const registerObject = {};
	
		switch(type) {
			default:
			break;
			case Ingreso_Types.add:
				registerObject.description = description;
				registerObject.creditCard = creditCard;
				registerObject.product = selectedProduct?.doc;
				registerObject.cant =  cant !== '' ? cant : '1';
				registerObject.amount = amountValue !== '' ? amountValue : '0';
				registerObject.user = userData.type === 'admin' ? actualUserAdmin : userData.user;
				registerObject.type = type;
				
				break;
			case Ingreso_Types.tatoo:
				registerObject.creditCard = creditCard;
				registerObject.worker = selectedWorkerValue?.doc;
				registerObject.amount = amountValue !== '' ? amountValue : '0';
				registerObject.user = userData.type === 'admin' ? actualUserAdmin : userData.user;
				registerObject.type = type;
				break;
			case Ingreso_Types.perfo:
				registerObject.amount = amountValue !== '' ? amountValue : '0';
				registerObject.worker = selectedWorkerValue?.doc;
				registerObject.creditCard = creditCard;
				registerObject.cant = cant !== '' ? cant : '1';
				registerObject.user = userData.type === 'admin' ? actualUserAdmin : userData.user;
				registerObject.type = type;
				break;
			case Ingreso_Types.normal:
				registerObject.amount = amountValue !== '' ? amountValue : '0';
				registerObject.creditCard = creditCard;
				registerObject.cant = cant !== '' ? cant : '1';
				registerObject.user = userData.type === 'admin' ? actualUserAdmin : userData.user;
				registerObject.product = selectedValue?.doc;
				registerObject.type = type;
				break;
		}

		let params = {
			register: registerObject
		}

		if (userData.type === 'admin') {
			params.user = { user: actualUserAdmin, group: userData.group };
		}

		emitView('add-register', params, userData.type === 'admin');
		handleClose();
	}

	function renderConfirmButtons() {
		if (type === Ingreso_Types.search) {
			return null;
		}

		return (
			<DialogActions>
                <Button fullWidth variant="contained" onClick={handleClose}>Cancelar</Button>
                <Button fullWidth disabled={!canSend} variant="contained" onClick={handleAcept}>Confirmar</Button>
            </DialogActions>
		);
	}

	function renderCard() {
		if (type === Ingreso_Types.search) {
			return null;
		}

		return (
			<Stack sx={{ mb: 1, mt: 1 }}>
				<ToggleButtonGroup
                    color="primary"
                    value={creditCard}
                    exclusive
					fullWidth
                    onChange={(e, val) => {
                        if (val !== null) {
                            setCreditCard(val)
                        }
                    }}
                >
                    <ToggleButton value={true}><CreditCardIcon/> Tarjeta</ToggleButton>
                    <ToggleButton value={false}><LocalAtmIcon/> Efectivo</ToggleButton>
                </ToggleButtonGroup>
			</Stack>
		);
	}
	function renderPrice() {
		if (type === Ingreso_Types.search) {
			return null;
		}

		return (
			<Stack direction="column" sx={{mt:1, mb: 1, width: '100%'}}>
				{type === Ingreso_Types.tatoo || type === Ingreso_Types.add
					? <TextField
						margin="dense"
						id="price"
						label="Precio"
						type="number"
						fullWidth
						value={price}
						onChange={(ev) => setPrice(ev.target.value)}
						InputProps={{
							inputMode: 'numeric',
							pattern: '[0-9]*'
						}}
					/>
					: null
				}
				{type !== Ingreso_Types.tatoo || type !== Ingreso_Types.search
					? <Stack direction="row" sx={{mt:1, mb: 1, width: '100%'}} justifyContent="space-between">
						<Typography align='left'>
							Total: 
						</Typography>
						<Typography align='right' sx={{fontWeight: 600}}>
							${amountValue}
						</Typography>
					</Stack>
					: null
				}
			</Stack>
		);
	}

	useEffect(() => {
		emitView('load-products', { group: userData.group });
		emitView('load-workers', { group: userData.group });
	}, []);

	useEffect(() => {
		const base = [
			{ doc: {name: 'Ingresar a caja', _id:Ingreso_Types.add}},
			{ doc: {name: 'Tatuaje', _id:Ingreso_Types.tatoo}},
			{ doc: {name: 'Perforación', _id:Ingreso_Types.perfo, price: perfoPrice}}
		];
		let list = [...base];

		if (products.docs.length > 0 && type !== Ingreso_Types.add) {
			list = [...list, ...products.docs];
		} else {
			list = products.docs;
		}

		setProductList(list);
	}, [products, type, perfoPrice]);

	useEffect(() => {
		if (!!selectedValue) {
			const id = selectedValue.doc._id;
			switch(id) {
				default:
					setType(Ingreso_Types.normal);
					setPrice(selectedValue.doc.price);
					setSelectedProduct(null);
					setDescription('');
					setCant('1');
					break;
				case Ingreso_Types.tatoo:
					setType(id);
					setSelectedWorkerValue(null);
					setSelectedProduct(null);
					setDescription('');
					setPrice('0');
					break;
				case Ingreso_Types.perfo:
					setType(id);
					setSelectedWorkerValue(null);
					setPrice(selectedValue.doc.price);
					setSelectedProduct(null);
					setDescription('');
					setCant('1');
					break;
				case Ingreso_Types.add:
					setType(id);
					setPrice('0');
					setDescription('');
					setCant('1');
					break;
			}
		} else {
			setType(Ingreso_Types.search);
		}
	}, [selectedValue]);

	useEffect(() => {
		let list = [];

		if (type === Ingreso_Types.tatoo) {
			list = workers.docs.filter(w => w.doc.type === 'T' || w.doc.type === 'TyP' || w.doc.type === 'All');
		} else if (type === Ingreso_Types.perfo) {
			list = workers.docs.filter(w => w.doc.type === 'P' || w.doc.type === 'TyP' || w.doc.type === 'All');
		}

		setWorkerList(list);
	}, [workers, type, selectedValue, active]);

	useEffect(() => {
		if (type !== Ingreso_Types.tatoo) {
			const priceValue = parseInt(price, 10);
			const cantValue = parseInt(cant, 10);
			const totalValue = priceValue * cantValue;

			setAmountValue(totalValue);
		} else {
			setAmountValue(price);
		}
	}, [price, cant, type])

	useEffect(() => {
		let can = false;

		if (type === Ingreso_Types.add) {
			if (description !== '' && parseInt(cant, 10) > 0) {
				can = true;
			}
		}

		if (type === Ingreso_Types.tatoo) {
			if ( !!selectedWorkerValue?.doc) {
				can = true;
			}
		}

		if (type === Ingreso_Types.perfo) {
			if ( !!selectedWorkerValue?.doc && parseInt(cant, 10) > 0) {
				can = true;
			}
		}

		if (type === Ingreso_Types.normal) {
			if (parseInt(cant, 10) > 0) {
				can = true;
			}
		}

		setCanSend(can);
	}, [type, selectedWorkerValue, cant, description]);

	if (active !== 0) {
		return null;
	}

	return (
		<Box m={1}>
			<Autocomplete
				margin="normal"	
				fullWidth
				getOptionLabel={(option) => option.doc.name}
				renderOption={(props, option) => (
					<Stack direction='row' {...props}>
						<Stack sx={{width: '100%'}}>
							{option.doc.name}
						</Stack>
						{!!option.doc.price
							? <>
								<Divider orientation="vertical" flexItem/>
								<Stack sx={{width: 100, ml: 1}}>
									{`$ ${option.doc.price}`}
								</Stack>
								</>
							: null
						}
						
					</Stack>
					)}
				options={productList}
				value={selectedValue}
				label="Buscar"
				name="Buscar"
				
				filterSelectedOptions
				onChange={(ev, val) => {
					setSelectedValue(val);
				}}
				renderInput={(params) => <TextField {...params} label="Buscar" />}
			/>

			{renderAddThings()}
			{renderWorker()}
			{renderCard()}
			{renderCant()}
			{renderPrice()}
			{renderConfirmButtons()}
		</Box>
	);
}

export const Egreso_Types = {
	payment: 'payment',
	expense: 'expense'
}

function Egreso(props) {
	const { active } = props;
	const { emitView, workers, userData, actualUserAdmin } = useAppContext();
	const [pago, setPago] = useState(Egreso_Types.expense);
	const [amount, setAmount] = useState('');
	const [description, setDescription] = useState('');
	const [selectedWorkerValue, setSelectedWorkerValue] = useState(null);
	const [workerList, setWorkerList] = useState([]);
	const [can, setCan] = useState(false);

	function handleClose() {
		setSelectedWorkerValue(null);
		setAmount('');
		setDescription('');
	}

	function submitChange() {
		const registerObject = {};
	
		switch(pago) {
			default:
			case Egreso_Types.payment:
				registerObject.worker = selectedWorkerValue?.doc;
				registerObject.amount = amount !== '' ? amount : '0';
				registerObject.user = userData.type === 'admin' ? actualUserAdmin : userData.user;
				registerObject.type = pago;
				break;
			case Egreso_Types.expense:
				registerObject.description = description;
				registerObject.amount = amount !== '' ? amount : '0';
				registerObject.user = userData.type === 'admin' ? actualUserAdmin : userData.user;
				registerObject.type = pago;
				break;
		}

		let params = {
			register: registerObject
		}

		if (userData.type === 'admin') {
			params.user = { user: actualUserAdmin, group: userData.group };
		}

		emitView('add-register', params, userData.type === 'admin');
		handleClose();
	}

	useEffect(() => {
		let list = [];
		list = workers.docs;

		setWorkerList(list);
	}, [workers]);

	useEffect(() => {
		handleClose();
	}, [pago, active]); 

	useEffect(() => {
		let isActive = false;

		switch(pago) {
			default:
			case Egreso_Types.payment:
				if (parseInt(amount) > 0 && !!selectedWorkerValue?.doc) {
					isActive = true;
				}
				break;
			case Egreso_Types.expense:
				if (parseInt(amount) > 0 && description !== '') {
					isActive = true;
				}
				break;
		}

		setCan(isActive);
	},[amount, pago, selectedWorkerValue, description]);

	if (active !== 1) {
		return null;
	}

	return (
		<Box m={1}>
			<Stack>
			<ToggleButtonGroup
				color="primary"
				value={pago}
				fullWidth
				exclusive
				onChange={(e, val) => setPago(val)}
			>
				<ToggleButton value={Egreso_Types.expense}>Registrar Gasto</ToggleButton>
				<ToggleButton value={Egreso_Types.payment}>Pago a empleado</ToggleButton>
			</ToggleButtonGroup>

				{pago === Egreso_Types.expense
					? <TextField
						margin="normal"	
						fullWidth
						label='Descripción'
						name="Descripción"
						value={description}
						onChange={ev => setDescription(ev.target.value)}
						
					/>
					: <Autocomplete
						margin="normal"	
						fullWidth
						getOptionLabel={(option) => option.doc.nickName}
						options={workerList}
						value={selectedWorkerValue}
						label="Empleado"
						name="Empleado"
						
						filterSelectedOptions
						sx={{ mt: 2, mb: 1 }}
						onChange={(ev, val) => {
							setSelectedWorkerValue(val);
						}}
						renderInput={(params) => <TextField {...params} label="Empleado" />}
					/>

				}

				<TextField
					margin="normal"	
					fullWidth
					label="Monto"
					value={amount}
					onChange={ev => setAmount(ev.target.value)}
					name="Monto"
				/>

				<Stack direction="row" spacing={2}>
					<Button
							fullWidth
							variant="contained"
							onClick={submitChange}
							disabled={!can}
						>
						Generar Egreso
					</Button>
				</Stack>
			</Stack>
		</Box>
	);
}

function CerrarCaja(props) {
	const { active } = props;
	const { emitView, homeData, workersData, registers, userData, actualUserAdmin } = useAppContext();

	const [change, setChange] = useState('');
	const [surplus, setSurplus] = useState('');

	if (active !== 2) {
		return null;
	}

	function handleConfirm() {
		const registerObject = {};

		registerObject.type = 'close-box';
		registerObject.change = change !== '' ? change : '0';
		registerObject.user = userData.type === 'admin' ? actualUserAdmin : userData.user;
		registerObject.surplus = surplus !== '' ? surplus : '0';
		registerObject.registers = registers;
		registerObject.homeData = homeData;
		registerObject.workersData = workersData;

		let params = {
			register: registerObject
		}

		if (userData.type === 'admin') {
			params.user = { user: actualUserAdmin, group: userData.group };
		}

		emitView('close-box', params, userData.type === 'admin');
	}

	return (
		<Box m={1}>
			<TextField
				margin="normal"	
				fullWidth
				label="Cambio"
				name="Cambio"
				value={change}
				onChange={ev => setChange(ev.target.value)}
				
			/>

			<TextField
				margin="normal"	
				fullWidth
				label="Sobrante"
				value={surplus}
				onChange={ev => setSurplus(ev.target.value)}
				name="Sobrante"
			/>

			<Stack direction="row" spacing={2}>
				<Button
					onClick={handleConfirm}
					fullWidth
					variant="contained"
				>
					Cerrar Caja
				</Button>
			</Stack>
		</Box>
	);
}

function OpenBox(props) {
	const { emitView, userData } = useAppContext();

	const [open, setOpen] = useState(false);
	function handleConfirm() {
		emitView('open-box');
	}

	if (userData.type === 'admin') {
		return null;
	}

	return (
		<Stack p={2}>
			{/* <Button
				onClick={() => setOpen(true)}
				fullWidth
				variant="contained"
				sx={{mb:2}}
			>
				Cambiar Cambio/Sobrante
			</Button> */}

			<Button
				onClick={handleConfirm}
				fullWidth
				variant="contained"
			>
				Abrir caja nueva
			</Button>
		</Stack>
	);
}

export default function Controlador(props) {
	const { emitView, isClosed, userData, actualUserAdmin, lastBox, setActualBox } = useAppContext();
	const [active, setActive] = useState(0);
	const [option, setOption] = useState({});
	const [optionList, setOptionList] = useState([]);

	function setNewDate(date) {
		setOption(date);
		setActualBox(`${getZero(date.c.day)}-${getZero(date.c.month)}-${getZero(date.c.year)}`);
		function getZero(number) {
            return number < 10 ? `0${number}` : number;
        }

		let params = {
            user: actualUserAdmin,
			group: userData?.group,
			box: `${getZero(date.c.day)}-${getZero(date.c.month)}-${getZero(date.c.year)}`
        };

		emitView('use-user', params, true);
	}

	useEffect(() => {
		// setOption(lastBox.lastBox.box);
		if (!!lastBox?.lastBox.box) {
			const time = lastBox.lastBox.box.split("-");
			const date = new Date(time[2],(time[1] - 1), time[0]);
			setOption(date);
		}

	}, [lastBox]);

	function renderSelect() {
		return (
			<Paper sx={{p: 1, mb: 1}}>
				<LocalizationProvider locale='arg' dateAdapter={DateAdapter}>
					<MobileDatePicker
						label="Fecha"
						views={['day', 'month', 'year']}
						value={option}
						onChange={(newValue) => {
							setNewDate(newValue);
						}}
						renderInput={(params) => <TextField {...params} />}
					/>
				</LocalizationProvider>
			</Paper>
		);
	}
	return (
		<>
			{userData?.type === 'admin'
				? renderSelect()
				: null
			}

			<Paper>
				{!isClosed
					? <Stack>
						<Tabs variant="fullWidth" value={active} onChange={(ev, val) => setActive(val)}>
							<Tab label='Ingreso' value={0}/>
							<Tab label='Egreso' value={1}/>
							<Tab label='Cerrar Caja' value={2}/>
						</Tabs>

						<Ingreso active={active}/>

						<Egreso active={active}/>

						<CerrarCaja active={active}/>
					</Stack>
					: <OpenBox/>
				}
			</Paper>
		</>
	);
}