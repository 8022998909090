import {Button, InputAdornment, TextField, Dialog, DialogActions, DialogContent, ToggleButtonGroup, ToggleButton, Stack, FormControl, Select, MenuItem, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { useAppContext } from '../../context/context';

export default function AddPrice(props) {
    const { open, setOpen, product, category, onAcept } = props;
    const { addPriceAmount } = useAppContext();
    
    const [type, setType] = useState(0);
    const [amount, setAmount] = useState('');

    function handleClose() {
        setType(0);
        setAmount('');
        setOpen(false);
    }

    function handleAcept() {
        let typeAmount = type === 0 ? 'percentage' : 'amount';

        if (amount !== '') {
            addPriceAmount(category, typeAmount, amount);
        }

        handleClose();
    }

    return (
        <Dialog  open={open} onClose={handleClose}>
            <DialogTitle>{`Precios con los productos de ${category?.name}`}</DialogTitle>

            <DialogContent>
                <ToggleButtonGroup
                    color="primary"
                    value={type}
                    exclusive
                    onChange={(e, val) => {
                        if (val !== null) {
                            setType(val)
                        }
                    }}
                >
                    <ToggleButton value={0}>Agregar por %</ToggleButton>
                    <ToggleButton value={1}>Agregar por monto fijo</ToggleButton>
                </ToggleButtonGroup>

                <TextField
                    margin="dense"
                    id="amount"
                    label="Cantidad"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={amount}
                    onChange={(ev) => setAmount(ev.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{type === 0 ? '%' : '$'}</InputAdornment>,
                        inputMode: 'numeric',
                        pattern: '[0-9]*'
                    }}
                />

            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleAcept}>Agregar</Button>
            </DialogActions>
        </Dialog>
    );
}