import './deposit.css';
import { Stack, CssBaseline, Paper, Button, Divider, Typography, TextField,  Table , TableBody, TableCell , TableHead, TableRow, IconButton, Tooltip } from '@mui/material';
import { useState, useEffect } from 'react';
import MenuNav from '../../components/MenuNav/MenuNav.jsx';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EditIcon from '@mui/icons-material/Edit';
import AddCategory from './AddCategory.jsx';
import AddProduct from './AddProduct.jsx';
import AddPrice from './AddPrice.jsx';
import AddStock from './AddStock.jsx';
import ChangePerfo from './ChangePerfo.jsx';
import { useAppContext } from '../../context/context';
import { useNavigate } from "react-router-dom";

function RowCategory(props) {
	const { category, onEdit, onSelectCategory, onCreateProduct, onPriceEdit } = props;
	const { userData, emitView } = useAppContext();

	function getProductList() {
		onSelectCategory(category);
	}

    return (
        <>
            <Stack p={1} direction="row" justifyContent="space-between">
                <Typography>
                    {category.name}
                </Typography>
				<Stack direction="row">
					<IconButton onClick={() => onEdit(category)}><Tooltip title='Editar'><EditIcon/></Tooltip></IconButton>

					<IconButton onClick={() => onPriceEdit(category)}><Tooltip title='Cambiar precio general'><AttachMoneyIcon/></Tooltip></IconButton>

					<IconButton onClick={() => onCreateProduct(category)}><Tooltip title='Agregar Producto'><AddIcon/></Tooltip></IconButton>

					<IconButton onClick={getProductList}><Tooltip title='Ver productos'><KeyboardArrowRightIcon/></Tooltip></IconButton>
				</Stack>
            </Stack>
            <Divider variant="middle"/>
        </>
    );
}

export default function Deposit(props) {
	const { userData, emitView, categories, products, isMobile, stock } = useAppContext();
	const [active, setActive] = useState(0);
	const navigate = useNavigate();

	const [categorySelected, setCategorySelected] = useState(null);
	const [categoryFilter, setCategoryFilter] = useState(null);
	const [productSelected, setProductSelected] = useState(null);
	const [search, setSearch] = useState('');

	const [selected, setSelected] = useState('category');
	const [open, setOpen] = useState(false);

	const [productsList, setProductList] = useState([]);

	function openCategory() {
		setCategorySelected(null);
		setSelected('category');
		setOpen(true);
	}

	function openPerfo() {
		setSelected('perfo');
		setOpen(true);
	}

	function openProduct() {
		setProductSelected(null);
		setSelected('product');
		setOpen(true);
	}

	function openPrice(category) {
		setCategorySelected(category);
		setSelected('price');
		setOpen(true);
	}

	function handleEditCategory(category) {
		setCategorySelected(category);
		setSelected('category');
		setOpen(true);
	}

	function handleCreateProductCategory(category) {
		setCategorySelected(category);
		setProductSelected(null);
		setSelected('product');
		setOpen(true);
	}

	function handleEditProduct(product) {
		setProductSelected(product);
		setSelected('product');
		setOpen(true);
	}

	function handleAddStock(product) {
		setProductSelected(product);
		setSelected('stock');
		setOpen(true);
	}

	function clean() {
		setProductSelected(null);
		setSelected('category');
	}

	useEffect(() => {
		if (!!userData) {
			emitView('load-deposit', { group: userData?.group});
		}
	}, []);

	useEffect(() => {
		let list = [];

		if (!!categoryFilter) {
			list = products.docs.filter(p => p.doc.category === categoryFilter._id);
		} else {
			list = products.docs;
		}

		if (search !== '') {
			list = list.filter(p => p.doc.name.toUpperCase().includes(search.toUpperCase()));
		}

		setProductList(list);
	}, [products, categoryFilter, stock, search]);

	if (!userData) {
		navigate('/');
	}

	if (!userData) {
		return null;
	}

    return (
		<>
			<Stack
				style={{
					width: "100vw",
					minHeight: "100vh",
					height: '100%',
					background:'gray'
			}}>
				<MenuNav/>
				<Stack direction="row" style={{
					width: "100vw",
					minHeight: "80vh",
					height: '100%',
					background:'gray'
				}}>
						<CssBaseline/>
						<Paper sx={{padding: 1, m: 1, width: '100%'}}>
							<Stack direction='row' spacing={2}>
								<Button onClick={openCategory}>Agregar categoria</Button>
								<Button onClick={openProduct}>Agregar producto</Button>

								<Divider orientation="horizontal" flexItem />

                                <TextField
                                    margin="normal"
                                    label='Buscar'
                                    name="Buscar"
									value={search}
									onChange={ev => setSearch(ev.target.value)}
                                    fullWidth
                                    autoFocus
                                />
							</Stack>

							<Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
								<Stack sx={{width:isMobile ? '100%' : '30%'}}>
									<Typography variant='h6'>Servicios</Typography>
									<Stack p={1} direction="row" justifyContent="space-between">
										<Typography>
											Perforaciones
										</Typography>
										<Stack direction="row">
											<IconButton onClick={openPerfo}><Tooltip title='Cambiar precio'><AttachMoneyIcon/></Tooltip></IconButton>
										</Stack>
									</Stack>
									<Typography variant='h6'>Categorias</Typography>
									{
										categories.docs.map(category => (
										<RowCategory
											key={category.doc._id}
											onEdit={handleEditCategory}
											onSelectCategory={setCategoryFilter}
											onPriceEdit={openPrice}
											onCreateProduct={handleCreateProductCategory}
											category={category.doc}
										/>
										))
									}
								</Stack>

								<Stack sx={{width:isMobile ? '100%' : '70%'}}>
									<Typography variant='h4'>
										{!!categoryFilter
											? `Productos de ${categoryFilter.name}`
											: 'Productos'
										}
										
										{!!categoryFilter
											? <IconButton onClick={() => setCategoryFilter(null)}><Tooltip title='Cerrar categoria'><CloseIcon/></Tooltip></IconButton>
											: null
										}
									</Typography>

									<Table aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell>
													<Stack direction='column'>
														<div>Nombre</div>
														<div>Categoria</div>
													</Stack>
												</TableCell>
												<TableCell>Precio</TableCell>
												<TableCell>Cantidad</TableCell>
												<TableCell>Acciones</TableCell>
											</TableRow>
										</TableHead>

										<TableBody>

										{productsList.map(prod => {
											const categoryProd = categories.docs.find(c => c.id === prod.doc.category);
											return (
												<TableRow key={prod.id}>
												<TableCell>
													<Stack direction='column'>
														<div>{prod.doc.name}</div>
														<div>{categoryProd?.doc?.name}</div>
													</Stack>
												</TableCell>
												<TableCell>${prod.doc.price}</TableCell>
												<TableCell>{stock[prod.doc._id] ?? 0}</TableCell>
												<TableCell>
													<Stack direction='row'>
														<IconButton onClick={() => handleAddStock(prod.doc)}><Tooltip title='Agregar Stock'><AddIcon/></Tooltip></IconButton>

														<IconButton onClick={() => handleEditProduct(prod.doc)}><Tooltip title='Editar producto'><EditIcon/></Tooltip></IconButton>
													</Stack>
												</TableCell>
											</TableRow>
											);
										})}
										</TableBody>
									</Table>
								</Stack>
							</Stack>
						</Paper>
				</Stack>
			</Stack>
			<AddCategory open={open && selected === 'category'} setOpen={setOpen} category={categorySelected}/>

			<AddProduct open={open && selected === 'product'} onClose={clean} setOpen={setOpen} product={productSelected} categorySelected={categorySelected}/>
		
			<AddPrice open={open && selected === 'price'} setOpen={setOpen} category={categorySelected}/>
		
			<AddStock open={open && selected === 'stock'} setOpen={setOpen} product={productSelected}/>

			<ChangePerfo open={open && selected === 'perfo'} setOpen={setOpen}/>
		</>
	);
}