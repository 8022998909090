import { Divider , Paper, Stack, Typography, Tooltip, ListItem, ListItemText, IconButton } from '@mui/material';
import "./history.css";
import { useState, useEffect } from 'react';
import { useAppContext } from '../../../context/context';
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';
import { Egreso_Types, Ingreso_Types } from '../components/Controlador.jsx';
import EditIcon from '@mui/icons-material/Edit';
import EditRegister from './EditRegister.jsx';

function Row(props) {
    const { register } = props;
    const [open, setOpen] = useState(false);

    function handleClose() {
        setOpen(false);
    }

    function isNegative() {
        const isNegative = Object.values(Egreso_Types).includes(register.type);

        return isNegative ? '-' : '';
    }

    function renderDescription() {
        if (!!register.product) {
            if ((!!register.description)) {
                return `${register.product.name} - ${register.description}`;
            }

            return register.product.name;
        }

        if (!!register.description) {
            return register.description;
        }

        if (register.type === Ingreso_Types.tatoo) {
            return `Tatuaje de ${register.worker.nickName}`;
        }

        if (register.type === Ingreso_Types.perfo) {
            return `Perforación de ${register.worker.nickName}`;
        }

        if (register.type === Egreso_Types.payment) {
            return `Pago a ${register.worker.nickName}`;
        }

        if (register.type === 'close-box') {
            return `Caja cerrada - Sobrante: $${register.surplus} - Cambio: $${register.change}`;
        }

        return '';
    }

    function renderDate(date) {
        const { hour, minutes } = date;
        function getZero(number) {
            return number < 10 ? `0${number}` : number;
        }

        return `${getZero(hour)}:${getZero(minutes)}`;
    }

    function getBackground() {
        if (register.type === Ingreso_Types.tatoo) {
            return 'pink';
        }

        if (register.type === Ingreso_Types.perfo) {
            return '#ffea00';
        }

        if (register.type === 'close-box') {
            return 'tomato';
        }

        return 'white';
    }

    let dateParsed = renderDate(register.date);

    return (
        <>
            <Stack direction='row' sx={{background: getBackground(), px: 1}}>
                <Stack direction='row' alignItems="center" justifyContent="center">
                    <Typography m={1} sx={{fontSize: 10, minWidth: 25, maxWidth: 25}}>
                        {dateParsed}
                    </Typography>
                    <Divider orientation="vertical"/>
                </Stack>

                <Stack direction='row' justifyContent="space-between" sx={{width: '100%'}}>
                    <Typography m={1}>
                        {renderDescription()}
                    </Typography>
                    <Divider orientation="vertical"/>
                </Stack>

                {!!register.cant
                    ? <Stack direction='row' alignItems="center">
                        <Typography sx={{ml: 1}}>
                            x
                        </Typography>
                        <Typography sx={{ mr: 1, fontWeight: 600, minWidth: 20, maxWidth: 20 }}>
                            {register.cant}
                        </Typography>
                        <Divider orientation="vertical"/>
                    </Stack>
                    : null
                }

                {!!register.amount
                    ? <Stack direction='row' alignItems="center" sx={{minWidth: 80, maxWidth: 80}}>
                        <Typography  sx={{ml: 1, fontWeight: 600, fontSize: 20}}>
                            {isNegative()}${register.amount}
                        </Typography>
                    </Stack>
                    : null
                }

                <Stack direction='row' alignItems="center" justifyContent="center">
                    <Divider orientation="vertical" sx={{mr: 1}}/>
                    
                    <IconButton onClick={() => setOpen(true)}><Tooltip title='Editar registro'><EditIcon fontSize="small"/></Tooltip></IconButton>
                </Stack>
            </Stack>
            <Divider/>
            <EditRegister open={open} handleClose={handleClose} register={register}/>
        </>
    );
}

export default function History(props) {
    const { registers, isClosed, userData } = useAppContext();
    const [registerList, setRegisterList] = useState([]);

    useEffect(() => {
        function compare(a, b) {
            const dateA = new Date(a.date.year, a.date.month - 1, a.date.day, a.date.hour, a.date.minutes, a.date.seconds).getTime();
            const dateB = new Date(b.date.year, b.date.month - 1, b.date.day, b.date.hour, b.date.minutes, b.date.seconds).getTime();

            return dateA - dateB;
        }
        const historyList = registers.docs.sort((a,b) => compare(b, a));
        setRegisterList(historyList);
    }, [registers]);

    if ((registerList.length < 1) || (isClosed && userData.type !== 'admin')) {
        return null;
    }

    return (
        <Paper sx={{padding: 1, mt: 1}}>
            <Stack>
                <TransitionGroup>
                {registerList.map(register => (
                    <CSSTransition
                        key={register._id}
                        timeout={500}
                        classNames={{
                            enter: 'animate__animated animate__zoomIn',
                            exit: 'animate__animated animate__fadeOutLeft'
                        }}
                    >
                        <Row register={register}/>
                    </CSSTransition>
                ))}
                </TransitionGroup>
            </Stack>
        </Paper>
    );
}
