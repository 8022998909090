import { Table ,Tooltip , Button, TableBody, TableCell ,IconButton , TableHead, TableRow, Stack, Divider, CssBaseline, Typography, TextField, ToggleButtonGroup, ToggleButton, Paper  } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useAppContext } from '../../context/context';
import MenuNav from '../../components/MenuNav/MenuNav.jsx';

export default function HistoryLog(props) {
    const { isMobile, emitView, actualUserAdmin, logs, userData } = useAppContext();
    const navigate = useNavigate();
    const [logList, setLogList] = useState([]);

    useEffect(() => {
        if (!!userData) {
            emitView('load-logs', {local: actualUserAdmin });
        }
    }, [actualUserAdmin]);

    useEffect(() => {
        function compare(a, b) {
            const dateA = new Date(a.date.year, a.date.month - 1, a.date.day, a.date.hour, a.date.minutes, a.date.seconds).getTime();
            const dateB = new Date(b.date.year, b.date.month - 1, b.date.day, b.date.hour, b.date.minutes, b.date.seconds).getTime();

            return dateA - dateB;
        }
        const historyList = logs.sort((a,b) => compare(b.doc, a.doc));

        setLogList(historyList);
    }, [logs]);

    function renderDate(date) {
        const { hour, minutes } = date;
        function getZero(number) {
            return number < 10 ? `0${number}` : number;
        }

        return `${getZero(hour)}:${getZero(minutes)}`;
    }

    if (!userData) {
		navigate('/');
	}

	if (!userData) {
		return null;
	}

    return (
		<Stack style={{
			width: "100vw",
			minHeight: "100vh",
            height: '100%',
			background:'gray'
		}}>
			<MenuNav/>
			<Stack direction="row" style={{
				border: "solid gray",
				minWidth: "100%",
				minHeight: "100vh",
                height: '100%',
				background:'gray'
			}}>
					<CssBaseline/>

                    <Paper sx={{ width: '100%', padding: 1, margin: 1, overflow: 'hidden'}}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Hora</TableCell>
                                        <TableCell>Mensaje</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>

                                    {logList.map(reg => {
                                        
                                        return (
                                            <TableRow>
                                                <TableCell>{renderDate(reg.doc.date)}</TableCell>
                                                <TableCell>{reg.doc.message}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                    </Paper>
			</Stack>
		</Stack>
	);
}