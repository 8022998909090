import {Button, InputAdornment, TextField, Dialog, DialogActions, DialogContent, ToggleButtonGroup, ToggleButton, Stack, FormControl, Select, MenuItem, DialogTitle } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { useAppContext } from '../../context/context';

export default function ChangePerfo(props) {
    const { open, setOpen, onAcept } = props;
    const { perfoPrice, emitView } = useAppContext();
    
    const [amount, setAmount] = useState('');

    function handleClose() {
        setOpen(false);
    }

    function handleAcept() {
        if (amount !== '') {
            emitView('set-perfo-price', {price: amount});
        }

        handleClose();
    }

    useEffect(() => {
        setAmount(perfoPrice);
    }, [perfoPrice]);

    return (
        <Dialog  open={open} onClose={handleClose}>
            <DialogTitle>Precio de Perforación</DialogTitle>

            <DialogContent>
                <TextField
                    margin="dense"
                    id="amount"
                    label="Precio"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={amount}
                    onChange={(ev) => setAmount(ev.target.value)}
                    InputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*'
                    }}
                />

            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleAcept}>Agregar</Button>
            </DialogActions>
        </Dialog>
    );
}