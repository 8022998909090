import {Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, InputLabel, FormControl, Select, MenuItem, DialogTitle } from '@mui/material';
import { useState, useEffect } from 'react';
import { useAppContext } from '../../context/context';

export default function AddProduct(props) {
    const { open, setOpen, onClose, product, categorySelected } = props;
    const { addProduct, delProduct, editProduct, categories } = useAppContext();

    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [category, setCategory] = useState('');

    function handleClose() {
        setName('');
        setPrice('');
        setCategory('');
        setOpen(false);
        onClose();
    }

    function handleDelete() {
        delProduct(product);
        handleClose();
    }

    function onAcept() {
        if (!!product) {
            const newProduct = product;

            newProduct.name = name;
            newProduct.price = price;
            newProduct.category = category;

            editProduct(newProduct);
        } else {
            const productObject = {
                name, price, category
            };

            addProduct(productObject);
        }

        handleClose();
    }

    useEffect(() => {
        if (!!product) {
            console.log('hay producto');
            setName(product.name);
            setPrice(product.price);
            setCategory(product.category);
        }

        if (categorySelected && !product) {
            setCategory(categorySelected._id);
        }
    }, [product, categorySelected]);

    return (
        <Dialog  open={open} onClose={handleClose}>
            <DialogTitle>{!!product ? 'Editar' : 'Nuevo'} Producto</DialogTitle>

            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Nombre"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={name}
                    onChange={(ev) => setName(ev.target.value)}
                />

                <TextField
                    margin="dense"
                    id="price"
                    label="Precio"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={price}
                    onChange={(ev) => setPrice(ev.target.value)}
                />

                <FormControl sx={{mt: 1, mb: 1}} variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-standard-label">Categoria</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={category}
                    label="Category"
                    onChange={ev => setCategory(ev.target.value)}
                >
                    {categories.docs.map(cat => {
                        return (
                            <MenuItem key={cat.id} value={cat.id}>{cat.doc.name}</MenuItem>
                        );
                    })}
                </Select>
                </FormControl>
            </DialogContent>

            <DialogActions>
                {!!product
                    ? <Button onClick={handleDelete}>Eliminar</Button>
                    : null
                }
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={onAcept}>{!!product ? 'Editar' : 'Agregar'}</Button>
            </DialogActions>
        </Dialog>
    );
}