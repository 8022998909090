import { Stack, Button, Container, CssBaseline, Paper, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useAppContext } from '../../context/context';

export default function AdminPage(props) {
    const { locals, emitView, userData, actualUserAdmin, setActualUserAdmin } = useAppContext();
    const navigate = useNavigate();

    const [localList, setLocalList] = useState([]);

    function onClick(user) {
        let params = {
            user: user, group: userData.group
        };

        if (!!actualUserAdmin) {
            params.oldUser = actualUserAdmin;
        }

        setActualUserAdmin(user);
        emitView('use-user', params, true);
        navigate('/home');
    }

    useEffect(() => {
        setLocalList(locals.localsList);
    }, [locals]);

    return (
        <Container component="main" maxWidth="xl" style={{
			border: "solid gray",
			minWidth: "100%",
			height: "100vh",
			background:'gray'

		  }}>
            <Paper> 
                <Stack alignItems='center' justifyContent='center' direction='column' sx={{width: '100%'}}>
                    {localList.map(local => {
                        const { doc } = local;
                        return (
                            <Button
                                sx={{m: 2}}
                                variant="contained"
                                key={doc._id}
                                onClick={() => onClick(doc.user)}
                            >
                                {doc.name}
                            </Button>
                        );
                    })
                    }
                </Stack>
            </Paper>
        </Container>
    );
}