import {Button, InputAdornment, TextField, Dialog, DialogActions, DialogContent, ToggleButtonGroup, ToggleButton, Stack, FormControl, Select, MenuItem, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { useAppContext } from '../../context/context';

export default function AddPrice(props) {
    const { open, setOpen, product, onAcept } = props;
    const { addStock } = useAppContext();

    const [amount, setAmount] = useState('');

    function handleClose() {
        setAmount('');
        setOpen(false);
    }

    function handleAcept() {
        if (amount !== '') {
            addStock(product, amount);
        }

        handleClose();
        
    }

    return (
        <Dialog  open={open} onClose={handleClose}>
            <DialogTitle>{`Agregar stock a ${product?.name}`}</DialogTitle>

            <DialogContent>
                <TextField
                    margin="dense"
                    id="amount"
                    label="Cantidad"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={amount}
                    onChange={(ev) => setAmount(ev.target.value)}
                    InputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*'
                    }}
                />

            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleAcept}>Agregar</Button>
            </DialogActions>
        </Dialog>
    );
}