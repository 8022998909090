import { IconButton, Tooltip, Stack, Divider, Typography , DialogActions, Paper, ToggleButtonGroup, ToggleButton, TextField, Box, Tabs, Tab, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Payment from './Payment.jsx';
import { useAppContext } from '../../../context/context';

function WorkerRow(props) {
    const { workerData } = props;
    const { isMobile } = useAppContext();

    const [open, setOpen] = useState(false);

    function handleOnClose() {
        setOpen(false);
    }

    return (
        <>
            <Stack direction='row' sx={{ px: 1 }}>
                <Stack direction='row' alignItems="center" justifyContent="center">
                    <Stack direction='column' sx={{width: isMobile ? 'auto' : 100}}>
                        <Typography sx={{fontWeight: 600}}>
                            {workerData.doc.nickName}
                        </Typography>

                        <Typography sx={{fontSize: 10}}>
                            {workerData.doc.name} {workerData.doc.surName}
                        </Typography>
                    </Stack>

                    <Divider orientation="vertical"/>
                </Stack>

                <Stack direction='column' alignItems="center" justifyContent="center" sx={{mx: 1}}>
                    <Typography sx={{fontSize: 12, fontWeight: 600}}>
                        Perforaciones
                    </Typography>

                    <Typography sx={{fontSize: 18 }}>
                        {workerData.perfo}
                    </Typography>
                </Stack>

                <Stack direction='column' alignItems="center" justifyContent="center" sx={{mx: 1}}>
                    <Typography sx={{fontSize: 12, fontWeight: 600}}>
                        Tatuajes
                    </Typography>

                    <Typography sx={{fontSize: 18 }}>
                        {workerData.tatoo}
                    </Typography>
                </Stack>

                <Stack direction='column' alignItems="center" justifyContent="center" sx={{mx: 1, width: 100}}>
                    <Typography sx={{fontSize: 12, fontWeight: 600}}>
                        Pagos
                    </Typography>

                    <Typography sx={{fontSize: 18 }}>
                        ${workerData.pago}
                    </Typography>
                </Stack>

                <Stack direction='row' alignItems="center" justifyContent="center">
                    <Divider orientation="vertical" sx={{mr: 1}}/>
                    
                    <IconButton sx={{background: 'tomato', color: 'white'}} onClick={() => setOpen(true)}><Tooltip title='Realizar pago'><AttachMoneyIcon/></Tooltip></IconButton>
                </Stack>
            </Stack>
            <Divider/>
            <Payment open={open} handleClose={handleOnClose} workerData={workerData}/>
        </>
    );
}
export default function WorkerList(props) {
    const { workersData, registers, isClosed, userData } = useAppContext();

    const [workersList, setWorkerList] = useState([]);

    useEffect(() => {
        let newList = [];

        if (!isClosed || userData.type === 'admin') {
            Object.values(workersData).forEach(worker => {
                newList.push(worker);
            });
        }

        setWorkerList(newList);
    }, [workersData, registers]);

    if (workersList.length < 1) {
        return null;
    }

    return (
        <Paper sx={{padding: 1, mt: 1}}>
            <Stack>
                {workersList.map(w => (
                    <WorkerRow key={w.doc._id} workerData={w}/>
                ))
                }
            </Stack>
        </Paper>
    );
}