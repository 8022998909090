import { Box, Button, Container, CssBaseline, Paper, TextField, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAppContext } from '../../context/context';

export default function Login(props) {
	const { login, setUserData } = useAppContext();
	const navigate = useNavigate();

	const [user, setUser] = useState('');
	const [password, setPassword] = useState('');

	function loginClick() {
		login(user, password);
	}

	// TODO
	// useEffect(() => {
	// 	const conected = localStorage.getItem('user');
	// 	if (!!conected) {
	// 		const userParsed = JSON.parse(conected);

	// 		setUserData(userParsed);

	// 		if (userParsed.type === 'admin') {
	// 			navigate('/admin');
	// 		} else {
	// 			navigate('/home');
	// 		}
	// 	}
	// }, []);

	return (
		<Container component="main" maxWidth="xl" style={{
			border: "solid gray",
			minWidth: "100%",
			height: "100vh",
			background:'gray'
		  }}>
			<CssBaseline />
			<Box
			sx={{
				margin: 8,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}>
				<Paper>
					<Box
					sx={{
						margin: 2,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}>
						<Typography component="h1" variant="h5">
							DRACO
						</Typography>

						<TextField
							margin="normal"	
							fullWidth
							label="Usuario"
							name="usuario"
							autoFocus
							value={user}
							onChange={e => setUser(e.target.value)}
						/>

						<TextField
							margin="normal"
							fullWidth
							type='password'
							label="Contraseña"
							name="contraseñá"
							value={password}
							onChange={e => setPassword(e.target.value)}
						/>

						<Button
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
							onClick={loginClick}
						>
							Ingresar
						</Button>
					</Box>
				</Paper>
			</Box>
		</Container>
	);
}