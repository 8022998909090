import { createTheme, ThemeProvider } from '@mui/material/styles';
import { red, yellow } from '@mui/material/colors';
import { Provider } from './context/context';
import { ClientFormProvider } from './context/clientFormContext';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Login from './routes/login/Login.jsx';
import Home from './routes/home/Home.jsx';
import Clients from './routes/clients/Clients.jsx';
import Deposit from './routes/deposit/Deposit.jsx';
import Employees from './routes/employees/Employees.jsx';
import AdminPage from './routes/admin/Admin.jsx';
import HistoryLog from './routes/historyLog/HistoryLog.jsx';
import ClientForm from './routes/clientForm/ClientForm.jsx';

const theme = createTheme({
  palette: {
    primary: {
      main: red[500],
    },
    secondary: {
      main: yellow[500],
    },
  },
});

function App() {
  return (
    <Provider>
      <ClientFormProvider>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path='/' element={<Login/>}/>
            <Route path='admin' element={<AdminPage/>}/>
            <Route path='home' element={<Home/>}/>
            <Route path='clients' element={<Clients/>}/>
            <Route path='deposit' element={<Deposit/>}/>
            <Route path='employees' element={<Employees/>}/>
            <Route path='log' element={<HistoryLog/>}/>
            <Route path='formulario'>
              <Route path=':lugar/:sucursal' element={<ClientForm/>}/>
            </Route>
          </Routes>
        </ThemeProvider>
      </ClientFormProvider>
    </Provider>
  );
}

export default App;
