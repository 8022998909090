import { useState, useEffect } from 'react';
import {Button, TextField, Dialog, DialogActions, DialogContent, FormControl, InputLabel, Select, MenuItem, DialogTitle } from '@mui/material';
import { useAppContext } from '../../../context/context';

export default function NewEmployee(props) {
    const { open, setOpen, employee, onClose } = props;
    const { emitView, addWorker, editWorker } = useAppContext();

    const [name, setName] = useState('');
    const [surName, setSurName] = useState('');
    const [nickName, setNickName] = useState('');
    const [phone, setPhone] = useState('');
    const [type, setType] = useState('');

    function handleClose() {
        setName('');
        setSurName('');
        setNickName('');
        setPhone('');
        setType('');
        setOpen(false);
        onClose();
    }

    function handleDelete() {
        emitView('delete-worker', { worker: employee});
        handleClose();
    }

    function handleAcept() {
        if (!!employee) {
            const newWorker = employee;

            newWorker.name = name !== '' ? name : 'sin nombre';
            newWorker.surName = surName !== '' ? surName : 'sin apellido';
            newWorker.nickName = nickName !== '' ? nickName : 'sin sobrenombre';
            newWorker.phone = type !== '' ? type : 'sin teléfono';
            newWorker.type = type !== '' ? type : 'All';

            editWorker(newWorker);
        } else {
            const worker = {
                name: name !== '' ? name : 'sin nombre',
                surName: surName !== '' ? surName : 'sin apellido',
                nickName: nickName !== '' ? nickName : 'sin sobrenombre',
                phone: type !== '' ? type : 'sin teléfono',
                type: type !== '' ? type : 'All'
            }

            addWorker(worker);
        }

        handleClose();
    }

    useEffect(() => {
        if (!!employee) {
            setName(employee.name);
            setSurName(employee.surName);
            setNickName(employee.nickName);
            setPhone(employee.phone);
            setType(employee.type);
        }
    }, [employee])

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{!!employee ? 'Editar' : 'Nuevo'} Empleado</DialogTitle>

            <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Nombre"
                type="text"
                fullWidth
                variant="standard"
                value={name}
                onChange={e => setName(e.target.value)}
            />

            <TextField
                margin="dense"
                id="name"
                label="Apellido"
                type="text"
                fullWidth
                variant="standard"
                value={surName}
                onChange={e => setSurName(e.target.value)}
            />

            <TextField
                margin="dense"
                id="name"
                label="Sobrenombre"
                type="text"
                fullWidth
                variant="standard"
                value={nickName}
                onChange={e => setNickName(e.target.value)}
            />

            <FormControl variant="standard" sx={{ marginTop: 1,marginBottom: 1, width: '100%' }}>
                <InputLabel id="demo-simple-select-filled-label">Trabajo</InputLabel>

                <Select
                    value={type}
                    label="Trabajo"
                    onChange={e => setType(e.target.value)}
                >
                    <MenuItem value='T'>Tatuador</MenuItem>

                    <MenuItem value='P'>Perforador</MenuItem>

                    <MenuItem value='TyP'>Tatuador y Perforador</MenuItem>

                    <MenuItem value='E'>Encargado</MenuItem>

                    <MenuItem value='All'>Todos</MenuItem>
                </Select>
            </FormControl>

            <TextField
                margin="dense"
                id="name"
                label="Telefono"
                type="text"
                fullWidth
                variant="standard"
                value={phone}
                onChange={e => setPhone(e.target.value)}
            />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                {!!employee
                    ? <Button onClick={handleDelete}>Eliminar</Button>
                    : null
                }
                <Button onClick={handleAcept}>{!!employee ? 'Editar' : 'Agregar'}</Button>
            </DialogActions>
        </Dialog>
    );
}