import { createContext, useContext, useEffect, useState } from 'react';
import socketIOClient from "socket.io-client";
import { useNavigate, useLocation } from "react-router-dom";
import { Ingreso_Types, Egreso_Types } from  '../routes/home/components/Controlador';

export const appContext = createContext({});

export function Provider(props) {
    const { children } = props;

    const location = useLocation();
    const navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(false);
    const [socket, setSocket] = useState(null);
    const [userData, setUserData] = useState(null);
    const [formData, setFormData] = useState(null);
    const [hasSocket, setHasSocket] = useState(false);
    const [registers, setRegisters] = useState({error:false, docs:[]});
    const [categories, setCategories] = useState({error:false, docs:[]});
    const [products, setProducts] = useState({error: false, docs:[]});
    const [workers, setWorkers] = useState({error: false, docs: []});
    const [boxesList, setBoxesList] = useState({error:false, docs:[]});
    const [actualBox, setActualBox] = useState('');
    const [actualUserAdmin, setActualUserAdmin] = useState('');
    const [isClosed, setIsClosed] = useState(false);
    const [lastBox, setLastBox] = useState({});
    const [locals, setLocals] = useState({localsList:[]});
    const [clients, setClients] = useState([]);
    const [perfoPrice, setPerfoPrice] = useState('');
    const [homeData, setHomeData] = useState({
        total: 0,
        neto: 0,
        tarjeta: 0,
        efectivo: 0,
        gastos: 0,
        pagos: 0,
        sobrante: 0,
        cambio: 0
    });
    const [workersData, setWorkersData] = useState({});
    const [note, setNote] = useState([]);
    const [logs, setLogs] = useState([]);
    const [stock, setStock] = useState({});

    function setUser(user) {
        // TODO
        // localStorage.setItem('user', JSON.stringify(user));
        setUserData(user);
    }

    function logout() {
        // TODO
        // localStorage.removeItem('user');
        setUserData(null);
    }

    function generateData(registersArray) {
        const newData = {
            total: 0,
            neto: 0,
            tarjeta: 0,
            efectivo: 0,
            gastos: 0,
            pagos: 0,
            sobrante: 0,
            cambio: 0
        };

        const workersList = {}



        registersArray.forEach(register => {
            const { type, amount, creditCard } = register;

            switch (type) {
                case 'close-box':
                    newData.sobrante = parseInt(newData.sobrante) + parseInt(register.surplus);
                    newData.cambio = parseInt(newData.sobrante) + parseInt(register.change);
                    break;
                case Egreso_Types.expense:
                    newData.gastos = parseInt(newData.gastos) + parseInt(register.amount);
                    break;
                default:
                case Ingreso_Types.add:
                case Ingreso_Types.normal:
                    newData[(creditCard ? 'tarjeta' : 'efectivo')] = parseInt(newData[(creditCard ? 'tarjeta' : 'efectivo')]) + parseInt(amount);
                    break;
                case Ingreso_Types.tatoo:
                    newData[(creditCard ? 'tarjeta' : 'efectivo')] = parseInt(newData[(creditCard ? 'tarjeta' : 'efectivo')]) + parseInt(amount);
                    if (!!workersList[register.worker._id]) {
                        workersList[register.worker._id].tatoo = parseInt(workersList[register.worker._id].tatoo) + 1;
                    } else {
                        workersList[register.worker._id] = {
                            doc: register.worker,
                            name: `${register.worker.name} ${register.worker.surName}`,
                            nickName: register.worker.nickName,
                            tatoo: 1,
                            perfo: 0,
                            pago: 0
                        }
                    }
                    break;
                case Ingreso_Types.perfo:
                    newData[(creditCard ? 'tarjeta' : 'efectivo')] = parseInt(newData[(creditCard ? 'tarjeta' : 'efectivo')]) + parseInt(amount);
                    if (!!workersList[register.worker._id]) {
                        workersList[register.worker._id].perfo = parseInt(workersList[register.worker._id].perfo) + parseInt(register.cant);
                    } else {
                        workersList[register.worker._id] = {
                            doc: register.worker,
                            name: `${register.worker.name} ${register.worker.surName}`,
                            nickName: register.worker.nickName,
                            tatoo: 0,
                            perfo: parseInt(register.cant),
                            pago: 0
                        }
                    }
                    break;
                case Egreso_Types.payment:
                        newData.pagos = parseInt(newData.pagos) + parseInt(register.amount);
                    if (!!workersList[register.worker._id]) {
                        workersList[register.worker._id].pago = parseInt(workersList[register.worker._id].pago) + parseInt(parseInt(register.amount));
                    } else {
                        workersList[register.worker._id] = {
                            doc: register.worker,
                            name: `${register.worker.name} ${register.worker.surName}`,
                            nickName: register.worker.nickName,
                            tatoo: 0,
                            perfo: 0,
                            pago: parseInt(register.amount)
                        }
                    }
                    break;
            }

            newData['total'] = newData.efectivo + newData.tarjeta;
            newData['neto'] = newData.efectivo + newData.tarjeta - newData.gastos - newData.pagos;


            setWorkersData(workersList);
            setHomeData(newData);
        });
    }

    useEffect(() => {
        //'ws://localhost'
        const newSocket = socketIOClient();
        setSocket(newSocket);

        const engine = newSocket.io.engine;

        newSocket.on('connect', () => {
            console.log('me conecte');
        })

        newSocket.on('disconnect', () => {
            console.log('me desconecte');
        })

        engine.on("close", (reason) => {
            // called when the underlying connection is closed
            console.log('tiro close');
            console.log(reason);
        });

        newSocket.on('login-result', data => {
            if (!data.error) {
                setUser(data.user);
                if (data.user.type === 'admin') {
                    navigate('/admin');
                } else {
                    navigate('/home');
                }
            }
        });

        newSocket.on('get-perfo-price', price => {
            setPerfoPrice(price);
        })

        newSocket.on('get-last-box', box => {
            setLastBox(box);
            setActualBox(box.lastBox.box);
            setIsClosed(box.lastBox.isClosed);
        });

        newSocket.on('get-products', products => {
            setProducts(products);
        });

        newSocket.on('get-logs', result => {
            setLogs(result);
        });

        newSocket.on('get-stock', result => {
            setStock(result);
        });

        newSocket.on('get-stock', result => {
            setStock(result);
        });

        newSocket.on('get-categories', categories => {
            setCategories(categories);
        });

        newSocket.on('get-workers', workers => {
            setWorkers(workers);
        });

        newSocket.on('get-register', registers => {
            generateData(registers.docs);
            setRegisters(registers);
        });

        newSocket.on('get-note', serverNote => {
            setNote(serverNote);
        });

        newSocket.on('get-box-list', serverBoxList => {
            setBoxesList(serverBoxList.docs);
        })

        newSocket.on('get-clients', clientList => {
            setClients(clientList);
        });

        newSocket.on('get-locals', localList => {
            setLocals(localList);
        });

        newSocket.on('set-form-data', fData => {
            console.log('llego algo');
            setFormData(fData);
        });

        return () => newSocket.close();
    }, [setSocket]);

    useEffect(() => {
        if (socket && socket.connected) {
            setHasSocket(true);
        }
    }, [socket]);

    function login(user, password) {
        socket.emit('login', {user, password});
    }

    function addCategory(value) {
        socket.emit('add-category', { user: userData, category: { name: value }});
    }

    function addPriceAmount(category, type, amount) {
        socket.emit('add-price-amount', { user: userData, category: category, type: type, amount: amount });
    }

    function addStock(product, amount) {
        let userValue = userData.user;

        if (userData.type === 'admin') {
            userValue = actualUserAdmin;
        }

        socket.emit('add-stock', { user: userValue, group: userData.group, product: product, amount: amount });
    }

    function editCategory(category) {
        socket.emit('edit-category', {user:userData, category: category});
    }

    function addProduct(product) {
        socket.emit('add-product', {user: userData, product: product});
    }

    function delProduct(product) {
        socket.emit('del-product', {user: userData, product: product});
    }

    function editProduct(product) {
        socket.emit('edit-product', {user: userData, product: product});
    }

    function addWorker(worker) {
        socket.emit('add-worker', {user: userData, worker: worker});
    }

    function editWorker(worker) {
        socket.emit('edit-worker', {user: userData, worker: worker});
    }

    function emitView(action, values = {}, withoutUserData = false) {
        let sendValues = null;

        if (!withoutUserData) {
            sendValues = { user: userData };
        }

        if (!!values) {
            sendValues = {...sendValues, ...values };
        }
        
        socket.emit(action, sendValues);
    }
    function addNote(noteMessage) {
        socket.emit('add-note', {user: userData, note: noteMessage});
    }

    function deleteNote(noteMessage) {
        socket.emit('delete-note', {user: userData, note: noteMessage});
    }

    function getFormData(user, sucursal) {
        const envio = socket.emit('load-form-data', {user, sucursal });

        console.log(envio);
    }

    function getLocalData() {
        if (userData.type === 'local') {
            return userData;
        }

        if (userData.type === 'admin' && actualUserAdmin !== '') {
            let object = {};
            const config = locals.localsList.find(u => u.doc.user === actualUserAdmin);
            object.name = config.doc.name;
            object.groupName = userData.groupName;

            return object;
        }

        // aca hacer que si es admin retorne el local seleccionado.
        return {name: 'sin local', group: 'sin dueño'};
    }

    useEffect(() => {
        generateData([]);
    }, [actualBox]);

    useEffect(() => {
        if (window.innerWidth < 600) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);

    return (
        <appContext.Provider value={{
            isMobile, perfoPrice, clients, stock, logs, login, actualUserAdmin, setActualUserAdmin,
            setActualBox, locals, boxesList, lastBox, actualBox, isClosed, userData, note, homeData,
            workersData, registers, addNote, deleteNote, addWorker, editWorker, addStock, emitView, addPriceAmount,
            setUserData, getLocalData, addProduct, delProduct, editProduct, addCategory, editCategory, categories,
            products, workers, getFormData, formData,
            hasSocket
        }}>
            {children}
        </appContext.Provider>
    )
}

export function useAppContext() {
    const consumer = useContext(appContext);

    return consumer;
}