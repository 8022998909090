import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../../context/context';
import { Box, Button, Container, CssBaseline, Paper, TextField, Typography } from '@mui/material';

export default function ClientForm() {
	const { lugar, sucursal } = useParams();
	const [detail, setDetail] = useState(null);

	const { getFormData, hasSocket, formData } = useAppContext();

	useEffect(() => {
		if (hasSocket) {
			console.log(hasSocket);
			getFormData(lugar, sucursal);
		}
	}, [hasSocket]);

	useEffect(() => {
		if (formData) {
			setDetail(formData);
		}
	}, [formData]);

	if (!detail) {
		return (
			<Container component="main" maxWidth="xl" style={{
				border: "solid gray",
				minWidth: "100%",
				height: "100vh",
				background:'gray'
			}}>
				<CssBaseline/>
				<Box
			sx={{
				margin: 8,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}>
				<Paper>
					<Box
						sx={{
							margin: 2,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}
					}>
						cargando...
					</Box>
				</Paper>
			</Box>
			</Container>
		);
	}

	return (
		<Container component="main" maxWidth="xl" style={{
			border: "solid gray",
			minWidth: "100%",
			height: "100vh",
			background:'gray'
		}}>
			<CssBaseline />
		
			<Box
			sx={{
				margin: 8,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}>
				<Paper>
				<Box
					sx={{
						margin: 2,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}
				}>
					<Typography component="h1" variant="h5">
						Formulario de cliente
					</Typography>

					<Box
					sx={{
						margin: 2,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}
					}>
						{lugar}
					</Box>

					<Typography component="h1" variant="h5">
						{sucursal}
					</Typography>

					<Button
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
						onClick={() => {}}
					>
						Comenzar
					</Button>
				</Box>
				</Paper>
			</Box>
		</Container>
	);
}
