import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Ingreso_Types, Egreso_Types } from  '../routes/home/components/Controlador';

export const clientFormContext = createContext({});

const demoForm = [
    {
        type: 'start',
        image: '',
        sucursal: 'Demo'
    },
    {
        type: 'message',
        text: 'hola este es un texto'
    }
]

export function ClientFormProvider(props) {
    const [clientFormValues, setClientFormValues] = useState([]);
    const [actualInput, setActualInput] = useState(0);
    const [inputs, setInputs] = useState({});

    function getActualInput() {
        return clientFormValues[actualInput];
    }
    function nextInput() {
        if (clientFormValues.length <= (actualInput + 1)) {
            setActualInput(a => a + 1);
        } else {
            setActualInput(clientFormValues.length);
        }
    }

    function backInput() {
        if (0 >= (actualInput - 1)) {
            setActualInput(a => a - 1);
        } else {
            setActualInput(0);
        }
    }

    function getInput(tag) {
        return inputs[tag];
    }

    function setInput(tag, value) {
        setInputs(i => ({...i, [tag]: value}));
    }

    function clearInputs() {
        setInputs({});
    }

    return (
        <clientFormContext.Provider value={{
            getActualInput,
            nextInput,
            backInput,
            getInput,
            setInput,
            clearInputs
        }}>
            {props.children}
        </clientFormContext.Provider>
    );
}

export function useClientFormContext() {
    const consumer = useContext(clientFormContext);

    return consumer;
}