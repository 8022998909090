import { Dialog, DialogContent, DialogTitle, Typography, TextField, Stack, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Egreso_Types, Ingreso_Types } from './Controlador.jsx';
import { useAppContext } from '../../../context/context';

export default function EditRegister(props) {
    const {open, handleClose, register} = props;
    const { emitView, userData, actualUserAdmin, perfoPrice } = useAppContext();

    function isNegative() {
        const isNegative = Object.values(Egreso_Types).includes(register.type);

        return isNegative ? '-' : '';
    }

    function renderDate(date) {
        const { hour, minutes } = date;
        function getZero(number) {
            return number < 10 ? `0${number}` : number;
        }

        return `${getZero(hour)}:${getZero(minutes)}`;
    }

    const [anulateMod, setAnulateMod] = useState(false);
    const [amountMod, setAmountMod] = useState('');
    const [description, setDescription] = useState('');
    const [cant, setCant] = useState('');
    const [password, setPassword] = useState('');
    const [amount, setAmount] = useState('');

    function onClose() {
        setAnulateMod(false);
        setPassword('');
        setAmountMod('');
        setDescription('');
        setCant('');
        setAmount('');
    }

    useEffect(() => {
        if (!!register.product) {
            setAmountMod(register.product.price);
        }
        if (register.type === 'perfo') {
            setAmountMod(perfoPrice);
        }
        if (register.type === 'expense' || register.type === 'payment' || register.type === 'tatoo') {
            setAmountMod(register.amount);
        }
        if (register.type === 'add') {
            setAmountMod(parseInt(register.amount) / parseInt(register.cant));
        }
        if (!!register.description) {
            setDescription(register.description);
        }
        if (!!register.cant) {
            setCant(register.cant);
        }
        setAmount(register.amount);
    }, [register]);
    
    useEffect(() => {
        let value = 0;
        let cantValue = parseInt(cant || 1);
        let amountValue =  parseInt(amountMod || 0);

        value = cantValue * amountValue;

        setAmount(`${value}`);
    }, [cant, amountMod]);

    function submitChange() {
        const newRegister = register;

        if (amountMod !== '') {
            const cantAmount = cant !== '' ? cant : '1';
            newRegister.amount = parseInt(amountMod) * parseInt(cantAmount);
        }

        if (description !== '') {
            newRegister.description = description;
        }

        if (cant !== '') {
            newRegister.cant = cant;
        }

        let params = {
            register: newRegister
        }

        if (userData.type === 'admin') {
            params.user = { user: actualUserAdmin, group: userData.group };
        }

        emitView('edit-register', params, userData.type === 'admin');
        handleClose();
        onClose();
    }

    function anulateRegister() {
        if (password === userData.anulatePassword || !userData.anulatePassword) {
            let params = {
                register: register
            }
    
            if (userData.type === 'admin') {
                params.user = { user: actualUserAdmin, group: userData.group };
            }
            emitView('remove-register', params, userData.type === 'admin');
            handleClose();
            onClose();
        } else {
            setPassword('');
            setAnulateMod(false);
        }
    }

    function renderDescription() {
        if (!!register.product) {
            if ((!!register.description)) {
                return `${register.product.name} - ${register.description}`;
            }

            return register.product.name;
        }

        if (!!register.description) {
            return register.description;
        }

        if (register.type === Ingreso_Types.tatoo) {
            return `Tatuaje de ${register.worker.nickName}`;
        }

        if (register.type === Ingreso_Types.perfo) {
            return `Perforación de ${register.worker.nickName}`;
        }

        if (register.type === Egreso_Types.payment) {
            return `Pago a ${register.worker.nickName}`;
        }

        if (register.type === 'close-box') {
            return `Caja cerrada - Sobrante: $${register.surplus} - Cambio: $${register.change}`;
        }

        return '';
    }

    function handleOnClose() {
        onClose();
        handleClose();
    }

    function renderContent() {
        if (anulateMod) {
            return (
                <DialogContent>
                <Stack>
                    <TextField
                        margin="dense"
                        id="name"
                        label="Contraseña de sucursal"
                        type="password"
                        fullWidth
                        variant="standard"
                        value={password}
                        onChange={(ev) => setPassword(ev.target.value)}
                    />
                    

                    <Stack direction="row" spacing={1} sx={{mt: 1}}>
                        <Button
                                fullWidth
                                variant="contained"
                                onClick={handleClose}
                            >
                            Cancelar
                        </Button>

                        <Button
                                fullWidth
                                variant="contained"
                                onClick={anulateRegister}
                            >
                            Anular
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
            );
        }

        return (
            <DialogContent>
                <Stack>
                    <Typography>Caja del {register.box}</Typography>
                    <Typography>Hora: {renderDate(register.date)}hs</Typography>
                    {!!register.description
                        ? <TextField
                            margin="dense"
                            id="name"
                            label="Descripción"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={description}
                            onChange={(ev) => setDescription(ev.target.value)}
                        />
                        : <Typography>Descripción: {renderDescription()}</Typography>
                    }

                    {!!register.worker
                        ? <Typography>{`Realizado por ${register.worker.nickName}`}</Typography>
                        : null
                    }

                    {register.cant
                        ? <TextField
                            margin="dense"
                            id="name"
                            label="Cantidad"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={cant}
                            onChange={(ev) => setCant(ev.target.value)}
                        />
                        : null
                    }

                    {['add', 'expense', 'payment', 'tatoo'].includes(register.type)
                        ? <TextField
                        margin="dense"
                        id="name"
                        label="Monto"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={amountMod}
                        onChange={(ev) => setAmountMod(ev.target.value)}
                    />
                    : null
                    }

                    <Typography>Total: ${amount}</Typography>

                    <Stack direction="row" spacing={1} sx={{mt: 1}}>
                        <Button
                                fullWidth
                                variant="contained"
                                onClick={handleClose}
                            >
                            Cancelar
                        </Button>

                        <Button
                                fullWidth
                                variant="contained"
                                onClick={() => {
                                    if (!userData.anulatePassword) {
                                        anulateRegister();
                                    } else {
                                        setAnulateMod(true);
                                    }
                                }}
                            >
                            Anular
                        </Button>

                        <Button
                                fullWidth
                                variant="contained"
                                onClick={submitChange}
                            >
                            Editar
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        );
    }

    return (
        <Dialog  open={open} onClose={handleOnClose}>
            <DialogTitle>{anulateMod ? 'Anular movimiento' : 'Registro de movimiento'}</DialogTitle>

            {renderContent()}
        </Dialog>
    );
}