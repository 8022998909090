import { Divider , Paper, Stack, Typography, IconButton, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useAppContext } from '../../../context/context';
import { useState } from 'react';

export default function Datos(props) {
	const { homeData, actualBox, isClosed, userData } = useAppContext();

	const [hidden, setHidden] = useState(false);

	function toggleHidden() {
		setHidden(h => !h);
	}

	function renderData() {
		return (
			<Stack>
				<Stack direction="row" justifyContent="space-between">
					<Typography variant="h6" component="div" m={1}>
						Total: ${hidden ? '****,**' : homeData?.total}
					</Typography>

					<Typography variant="h6" component="div" m={1}>
						Neto: ${hidden ? '****,**' : homeData?.neto}
					</Typography>
				</Stack>

				<Divider orientation="horizontal" flexItem sx={{mb: 1}}/>

				<Stack direction="row" justifyContent="space-between">
					<Typography ml={1} component="div" >
						Tarjeta: ${hidden ? '****,**' : homeData?.tarjeta}
					</Typography>

					<Typography mr={1} component="div" >
						Efectivo: ${hidden ? '****,**' : homeData?.efectivo}
					</Typography>
				</Stack>

				<Stack direction="row" justifyContent="space-between">
					<Typography ml={1} component="div" >
						Gastos: ${hidden ? '****,**' : homeData?.gastos}
					</Typography>

					<Typography mr={1} component="div" >
						Pagos: ${hidden ? '****,**' : homeData?.pagos}
					</Typography>
				</Stack>

				<Stack direction="row" justifyContent="space-between">
					<Typography ml={1} component="div" >
						Sobrante: ${hidden ? '****,**' : homeData?.sobrante}
					</Typography>

					<Typography mr={1} component="div" >
						Cambio: ${hidden ? '****,**' : homeData?.cambio}
					</Typography>
				</Stack>
			</Stack>
		);
	}

	return (
		<Paper sx={{padding: 1}}>
			<Stack direction="row" alignItems='center' justifyContent='space-between' m={1}>
				<Typography sx={{fontWeight: 600}}>
					{isClosed ? 'caja: ' : 'Caja del día: '} {actualBox}
				</Typography>

				<IconButton onClick={toggleHidden}><Tooltip title='mostrar/ocultar valores'>
					{hidden
						? <VisibilityIcon/>
						: <VisibilityOffIcon/>
					}
				</Tooltip></IconButton>
			</Stack>

			{!isClosed || userData.type === 'admin'
				? <>
					<Divider orientation="horizontal" flexItem sx={{mb: 1}}/>

					{renderData()}
				</>
				: null
			}
			
		</Paper>
	);
}