import { useAppContext } from '../../context/context';
import { useEffect, useState, forwardRef } from 'react';
import { useNavigate } from "react-router-dom";
import { AppBar , Stack, Button, Toolbar, Typography, IconButton, SwipeableDrawer  , MenuItem, Menu, InputLabel } from '@mui/material';
import SelectUnstyled, { selectUnstyledClasses } from '@mui/base/SelectUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { styled } from '@mui/system';
import GroupIcon from '@mui/icons-material/Group';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import HomeIcon from '@mui/icons-material/Home';

const initialStateRoutes = [
    {
        title: 'Inicio',
        icon:<HomeIcon />,
        route:'/home'
    },
    {
        title: 'Clientes',
        icon:<GroupIcon />,
        route:'/clients'
    },
    {
        title: 'Productos',
        icon:<Inventory2Icon />,
        route:'/deposit'
    },
    {
        title: 'Empleados',
        icon:<AccountBoxIcon />,
        route:'/employees'
    }
];

// INICIO DE LOS COMPONENTES CUSTOM
const StyledButton = styled('button')`
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.8rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 18px);
  min-width: 200px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 0.5em;
  margin-bottom: 0.5em;
  padding: 5px;
  text-align: left;
  line-height: 1.5;
  color: #000;
  z-index: 100;
  position: relative;

  &.${selectUnstyledClasses.focusVisible} {
    outline: 4px solid rgba(100, 100, 100, 0.3);
  }

  &.${selectUnstyledClasses.expanded} {
    border-radius: 0.75em 0.75em 0 0;

    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
`;

const StyledListbox = styled('ul')`
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: #fff;
  min-width: 200px;
  border: 1px solid #ccc;
  border-top: none;
  color: #000;
`;

const StyledOption = styled(OptionUnstyled)`
  list-style: none;
  padding: 4px 10px;
  margin: 0;
  border-bottom: 1px solid #ddd;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.disabled} {
    color: #888;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: rgba(25, 118, 210, 0.08);
    z-index: 100;
    position: relative;
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: tomato;
    color: #fff;
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: tomato;
    color: #fff;
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: #ffea00;
  }
`;

const StyledPopper = styled(PopperUnstyled)`
  z-index: 10;
`;

const CustomSelect = forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});
// FIN DE LOS COMPONENTES CUSTOM

export default function MenuNav(props) {
    const { getLocalData, userData, locals, actualUserAdmin, setActualUserAdmin, emitView, isMobile } = useAppContext();
    const navigate = useNavigate();

    const [actualRoute, setActualRoute] = useState(null);
    const [listLocals, setListLocals] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);

    function goTo(zone) {
      console.log(zone);
      setActualRoute(zone);
		  navigate(zone, { replace: false });
  	}

    function renderListButtons() {
      let valuesRoutes = initialStateRoutes;

      if (userData?.type === 'admin') {
        valuesRoutes = [...initialStateRoutes, {
          title: 'Historial',
          icon:<AppRegistrationIcon />,
          route:'/log'
        }]
      }

      return valuesRoutes.map((route, index) => (
        <Button
            key={index}
            size={isMobile ? 'large' : ''}
            color={actualRoute === route.route ? 'secondary' : 'inherit'}
            startIcon={route.icon}
            style={{justifyContent: "flex-start" }}
            onClick={() => goTo(route.route)}
        >
                {route.title}
        </Button>
      ));
    }

    function renderButtons() {
      if (isMobile) {
        return (
        <>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2}}
            onClick={() => setOpenDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <SwipeableDrawer
            anchor='right'
            swipeAreaWidth={200}
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            sx={{p: 2}}
          >
            {renderListButtons()}
          </SwipeableDrawer>
        </>
        );
      }

      return renderListButtons();
    }

    function handleSelectLocal(local) {
        let params = {
            user: local, group: userData?.group
        };

        if (!!actualUserAdmin) {
            params.oldUser = actualUserAdmin;
        }

        setActualUserAdmin(local);
        emitView('use-user', params, true);
    }

    function renderSelectLocal() {
        return (
            <Stack direction="column">
                <Typography variant="h6" component="div" >
                    {!!getLocalData ? getLocalData().groupName : 'cargando...'}
                </Typography>
                <CustomSelect value={actualUserAdmin} onChange={val => handleSelectLocal(val)}>
                    {listLocals.map(local => {
                        const { doc } = local;

                        return (
                            <StyledOption key={doc._id} value={doc.user}>{doc.name}</StyledOption>
                        );
                    })}
                </CustomSelect>
            </Stack>
        );
    }

    useEffect(() => {
      if (!!locals) {
        setListLocals(locals.localsList);
      }
    }, [locals]);

    return (
        <AppBar position="static">
            <Toolbar>
                <Stack direction='row' sx={{flexGrow: 1}}>
                    {userData?.type === 'local'
                        ? <Stack sx={{ margin: 1 }}>
                            <Typography variant="h6" component="div" >
                                {!!getLocalData ? getLocalData().groupName : 'cargando...'}
                            </Typography>
                            <Typography component="div">
                                {!!getLocalData ? getLocalData().name : 'cargando...'}
                            </Typography>
                        </Stack>
                        : renderSelectLocal()
                    }
                </Stack>

                {initialStateRoutes.length > 0 
                    ? renderButtons()
                    : null
                }
            </Toolbar>
        </AppBar>
    );
}