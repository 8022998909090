import { Stack, CssBaseline } from '@mui/material';
import MenuNav from '../../components/MenuNav/MenuNav.jsx';
import Controlador from './components/Controlador.jsx';
import History from './components/History.jsx';
import Datos from './components/Datos.jsx';
import Notas from './components/Notas.jsx';
import WorkerList from './components/WorkerList.jsx';
import { useAppContext } from '../../context/context';
import { useNavigate } from "react-router-dom";

export default function Home(props) {
	const { userData, isMobile } = useAppContext();
	const navigate = useNavigate();

	if (!userData) {
		navigate('/');
	}

	if (!userData) {
		return null;
	}

	return (
		<Stack style={{
			width: "100vw",
			minHeight: "100vh",
			height: '100%',
			background:'gray'
		}}>
			<MenuNav/>
			<Stack direction={isMobile ? 'column-reverse' : 'row'} style={{
				border: "solid gray",
				height: '100%',
				background:'gray'
			}}>
					<CssBaseline/>
					<Stack style={{width: isMobile ? '100%' : '33.3%'}} sx={{padding: 1}}>
						{/* controlador */}
						<Controlador/>
						{/* historial */}
						<History/>
					</Stack>

					<Stack style={{width: isMobile ? '100%' : '33.3%'}} sx={{padding: 1}}>
						{/* visor */}
						<Datos/>
						{/* datos de selecciones */}
					</Stack>

					<Stack style={{width: isMobile ? '100%' : '33.3%'}} sx={{padding: 1}}>
						{/* notas */}
						<Notas/>
						{/* empleados del dia */}
						<WorkerList/>
					</Stack>
			</Stack>
		</Stack>
	);
}