import { Stack, CssBaseline, Paper, Button, Divider, Typography,  Table , TableBody, TableCell , TableHead, TableRow, IconButton, Tooltip } from '@mui/material';
import {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import MenuNav from '../../components/MenuNav/MenuNav.jsx';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useAppContext } from '../../context/context';
import EditIcon from '@mui/icons-material/Edit';
import NewEmployee from './components/newEmployee.jsx';

function WorkerRow(props) {
	const { isMobile } = useAppContext();
	const { worker, onEdit } = props;

	const tipos = {
		'T': 'Tatuador',
		'P':'Perforador',
		'TyP':'Tatuador y Perforador',
		'E':'Encargado',
		'All':'Todos'
	};

	function call() {
		window.open("https://api.whatsapp.com/send?phone="+worker.phone);
	}

	function editWorker() {
		onEdit(worker);
	}

	return (
		<TableRow>
			{isMobile
				? <>
				<TableCell>
						<Stack direction='column'>
							<Typography sx={{fontSize: 10, fontWeight: 'bold'}}>{worker.name} {worker.surName}</Typography>
							<Typography>{worker.nickName}</Typography>
						</Stack>
				</TableCell>
				</>
				: <>
					<TableCell>
						<Stack>
							{worker.name} {worker.surName}
						</Stack>
					</TableCell>
					<TableCell>{worker.nickName}</TableCell>
				</>
			}
			<TableCell>{tipos[worker.type]}</TableCell>
			<TableCell>
				<Stack direction='row'>
					<IconButton onClick={call}><Tooltip title='Abrir Whatsapp'><WhatsAppIcon/></Tooltip></IconButton>
					<IconButton onClick={editWorker}><Tooltip title='Editar datos'><EditIcon/></Tooltip></IconButton>
				</Stack>
			</TableCell>
		</TableRow>
	);
}
export default function Employees(props) {
	const { emitView, workers, userData, isMobile } = useAppContext();

	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState(null);
	const [searchFilter, setSearchFilter] = useState('');
	const [workersList, setWorkersList] = useState([]);
	const navigate = useNavigate();

	function handleOpen() {
		setOpen(true);
	}

	function handleClose() {
		setSelected(null);
	}

	function editWorker(employee) {
		setSelected(employee);
		setOpen(true);
	}

	useEffect(() => {
		if (!!userData) {
			emitView('load-workers', { group: userData.group});
		}
	}, []);

	useEffect(() => {
		let list = [];
	
		if (searchFilter !== '') {
			list = workers.docs.filter(w => w.doc.name.includes(searchFilter) || w.doc.surName.includes(searchFilter) || w.doc.nickName.includes(searchFilter));
		} else {
			list = workers.docs;
		}

		setWorkersList(list);
	}, [workers, searchFilter]);

	if (!userData) {
		navigate('/');
	}

	if (!userData) {
		return null;
	}


    return (
		<>
			<Stack style={{
				width: "100vw",
				minHeight: "100vh",
				height: '100%',
				background:'gray'
			}}>
				<MenuNav/>
				<Stack direction="row" style={{
					width: "100vw",
					minHeight: "80vh",
					height: '100%',
					background:'gray'
				}}>
					<CssBaseline/>

					<Paper sx={{padding: 1, m: 1, width: '100%'}}>
						<Stack direction='row' spacing={2}>
							<Button onClick={handleOpen}>Agregar empleado</Button>
							
							<Divider orientation="horizontal" flexItem />

							{/* <TextField
								margin="normal"
								label='Buscar'
								name="Buscar"
								fullWidth
								autoFocus
							/> */}
						</Stack>

						<Table aria-label="simple table" size='small'>
							<TableHead>
								<TableRow>
									<TableCell>Nombre</TableCell>
									{isMobile
										? null
										: <TableCell>Sobrenombre</TableCell>
									}
									<TableCell>Tipo</TableCell>
									<TableCell>Acciones</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>

							{workersList.map(worker => {

								return (
									<WorkerRow key={worker.id} worker={worker.doc} onEdit={editWorker}/>
								);
							})
							}
							
							</TableBody>
						</Table>
					</Paper>
				</Stack>
			</Stack>
			<NewEmployee employee={selected} open={open} setOpen={setOpen} onClose={handleClose}/>
		</>
    );
}