import { Dialog, DialogContent, TextField, DialogTitle, DialogContentText,  Stack, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Egreso_Types } from  './Controlador.jsx';
import { useAppContext } from '../../../context/context';

export default function Payment(props) {
    const {open, handleClose, workerData} = props;
    const { emitView, userData, actualUserAdmin } = useAppContext();

    const [amount, setAmount] = useState('');

    function submitChange() {
        const registerObject = {};
        registerObject.worker = workerData?.doc;
        registerObject.amount = amount;
        registerObject.user = userData.type === 'admin' ? actualUserAdmin : userData.user;
        registerObject.type = Egreso_Types.payment;

        const params = {
			register: registerObject
		}

		if (userData.type === 'admin') {
			params.user = { user: actualUserAdmin, group: userData.group };
		}

        emitView('add-register', params, userData.type === 'admin');
		handleClose();
    }

    useEffect(() => {
        setAmount('');
    }, [open]);

    return (
        <Dialog  open={open} onClose={handleClose}>
            <DialogTitle>Realizar pago a {workerData.doc.nickName}</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    Tatuajes realizados: {workerData.tatoo}<br/>
                    Perforaciones realizadas: {workerData.perfo}<br/>
                    Cantidad de pago recibido: ${workerData.pago}
                </DialogContentText>

                <Stack>
                    <TextField
                        margin="normal"	
                        fullWidth
                        label="Monto"
                        value={amount}
                        onChange={ev => setAmount(ev.target.value)}
                        name="Monto"
                    />

                    <Stack direction="row" spacing={2}>
                        <Button
                                fullWidth
                                variant="contained"
                                onClick={handleClose}
                            >
                            Cancelar
                        </Button>
                        <Button
                                fullWidth
                                variant="contained"
                                onClick={submitChange}
                            >
                            Pagar
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}