import { Table ,Tooltip , FormControl, Button, InputLabel, MenuItem,  TableBody, TableCell ,IconButton , Select,  TableHead, TableRow, Stack, Divider, CssBaseline, Typography, TextField, ToggleButtonGroup, ToggleButton, Paper  } from '@mui/material';
import { useNavigate } from "react-router-dom";
import CakeIcon from '@mui/icons-material/Cake';
import EditIcon from '@mui/icons-material/Edit';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useState, useEffect } from 'react';
import { useAppContext } from '../../context/context';
import MenuNav from '../../components/MenuNav/MenuNav.jsx';

function ClientRow(props) { 
    const { client } = props;
    const { isMobile } = useAppContext();
    const months = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ];

    function renderDate(date) {
        const { day, month, year } = date;
        function getZero(number) {
            return number < 10 ? `0${number}` : number;
        }

        return `${getZero(day)}/${getZero(month)}/${year}`;
    }

    function call(isBirthday) {
        const capitalized = ([first, ...rest]) => {
            first === undefined ? '' : first.toLocaleLowerCase() + rest.joint('');
        };
        const d = new Date();
        const month = d.getMonth();
        const monthParsed = months[month];

        let text = `Hola ${client.name}!`;

        if (isBirthday) {
            text = `Hola!👋🏻 te hablamos de Arte ${client.user}, por un formulario que llenaste sabemos que cumplis años en ${monthParsed}🎉🎊🎂👏 es por eso que te queremos hacer un regalo 🎁. Tenes un 15%off en piercings de acero y/o de titanio (exceptuando los importados)✨ para reclamar tu regalo tenes todo este mes de ${monthParsed}. La condición es que presentes el DNI y el chat 👀`;
        } else if (client.type === 'perfo') {
            text = `Hola ${client.name}! te hablamos de Arte ${client.user}, queriamos saber como iba cicatrizando la perforación y si tenias alguna duda con la curación`;
        } else {
            text = `Hola ${client.name}! te hablamos de Arte ${client.user}, queriamos saber como iba cicatrizando el tatuaje y si tenias alguna duda con la curación`;
        }
        const urlEncodedText = encodeURIComponent(text);

        window.open(`https://wa.me/549${client.phone}?text=${urlEncodedText}`);
    }

    return (
        <TableRow>
            <TableCell>
                <Stack>
                    <Typography>
                        {client.name}
                    </Typography>
                    <Typography>
                        {client.document}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell>
                <Stack direction='column'>
                    <Typography>
                        {client.user}
                    </Typography>
                    <Typography>
                        {renderDate(client.registerDate)}
                    </Typography>
                </Stack>
            </TableCell>
            {isMobile
                ? null
                : <>
                    <TableCell>{client.type === 'perfo' ? 'Perforación' : 'Tatuaje'}</TableCell>
                    <TableCell>{client.phone}</TableCell>
                    <TableCell>{renderDate(client.birthdayDate)}</TableCell>
                </>
            }
            <TableCell>
                <Stack direction='row'>
                    <IconButton onClick={() => call(true)}><Tooltip title='chat Cumpleaños'><CakeIcon/></Tooltip></IconButton>
                    <IconButton onClick={() => call(false)}><Tooltip title='chat servicio'><WhatsAppIcon/></Tooltip></IconButton>
                    <IconButton><Tooltip title='Editar datos'><EditIcon/></Tooltip></IconButton>
                </Stack>
            </TableCell>
        </TableRow>
    );
}

export default function Clients(props) {
    const { isMobile, emitView, userData, actualUserAdmin, clients } = useAppContext();
    const navigate = useNavigate();
    const [type, setType] = useState(0);
    const [clientList, setClientList] = useState([]);
    const [search, setSearch] = useState('');
    const [date, setDate] = useState({ year: 1993, month: 8 });

    useEffect(() => {
        if (!!userData) {
            let userValue = userData?.user;

            if (userData?.type === 'admin') {
                userValue = actualUserAdmin;
            }

            const dateStart = new Date();
            const year = dateStart.getFullYear();
            const month = dateStart.getMonth() + 1;

		    setDate({ year, month });
        }
    }, []);

    useEffect(() => {
        if (!!date) {
            let userValue = userData?.user;

            if (userData?.type === 'admin') {
                userValue = actualUserAdmin;
            }

            const { month, year } = date;
            emitView('enter-clients', { user: userValue, group: userData.group, month, year, isBirthday: type === 3 }, true);
        }
    }, [date, type]);

    useEffect(() => {
        let list = [];

        list = clients;

        if (type === 1) {
            list = list.filter(c => c.type === 'tatoo');
        }

        if (type === 2) {
            list = list.filter(c => c.type === 'perfo');
        }

        if (search !== '') {
            // si la busqueda tiene algun valor
            list = list.filter(c => {
                const text = `${c.name} ${c.surName}`;

                console.log(text);
                
                return text.toUpperCase().includes(search.toUpperCase());
            });
        }

        // Ordenar por fecha
        list = list.sort((a,b) => {
            const dateA = a.registerDate;
            const dateB = b.registerDate;
            const compareA = `${dateA.year}${dateA.month}${dateA.day}${dateA.hour}${dateA.minutes}${dateA.seconds}`;
            const compareB = `${dateB.year}${dateB.month}${dateB.day}${dateB.hour}${dateB.minutes}${dateB.seconds}`;

            return (parseInt(compareB) - parseInt(compareA));
        })


        setClientList(list);
    }, [clients, search, type]);

    if (!userData) {
		navigate('/');
	}

	if (!userData) {
		return null;
	}

    return (
		<Stack style={{
            width: "100vw",
            minHeight: "100vh",
            height: '100%',
            background:'gray'
        }}>
			<MenuNav/>
			<Stack direction="row" style={{
					width: "100vw",
					minHeight: "80vh",
					height: '100%',
					background:'gray'
				}}>
					<CssBaseline/>

                    <Paper sx={{padding: 1, m: 1, width: '100%'}}>
                        <Stack>
                            <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={type}
                                    exclusive
                                    onChange={(e, val) => setType(val)}
                                >
                                    <ToggleButton value={0}>Todos</ToggleButton>
                                    <ToggleButton value={1}>Tatuajes</ToggleButton>
                                    <ToggleButton value={2}>Perforaciones</ToggleButton>
                                    <ToggleButton value={3}>Cumpleaños</ToggleButton>
                                </ToggleButtonGroup>

                                <Divider orientation="horizontal" flexItem />

                                <TextField
                                    margin="normal"
                                    label='Año'
                                    name="Año"
                                    value={date.year}
                                    onChange={e => setDate(d => ({ year: parseInt(e.target.value ?? 2022), month: d.month}))}
                                    autoFocus
                                />

                                <FormControl sx={{width: 200}}>
                                <InputLabel id="demo-simple-select-label">Mes</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={date.month}
                                    label="Mes"
                                    onChange={
                                        ev => {
                                            setDate(d => ({ year: d.year, month: ev.target.value }))
                                        }
                                    }
                                >
                                    <MenuItem value={1}>Enero</MenuItem>
                                    <MenuItem value={2}>Febrero</MenuItem>
                                    <MenuItem value={3}>Marzo</MenuItem>
                                    <MenuItem value={4}>Abril</MenuItem>
                                    <MenuItem value={5}>Mayo</MenuItem>
                                    <MenuItem value={6}>Junio</MenuItem>
                                    <MenuItem value={7}>Julio</MenuItem>
                                    <MenuItem value={8}>Agosto</MenuItem>
                                    <MenuItem value={9}>Septiembre</MenuItem>
                                    <MenuItem value={10}>Octubre</MenuItem>
                                    <MenuItem value={11}>Noviembre</MenuItem>
                                    <MenuItem value={12}>Diciembre</MenuItem>
                                </Select>
                                </FormControl>

                                <Divider orientation="horizontal" flexItem />

                                <TextField
                                    margin="normal"
                                    label='Buscar'
                                    name="Buscar"
                                    fullWidth
                                    value={search}
                                    onChange={e => setSearch(e.target.value)}
                                    autoFocus
                                />
                            </Stack>

                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Cliente</TableCell>
                                        <TableCell>Registro</TableCell>
                                        {isMobile
                                            ? null
                                            : <>
                                                <TableCell>Tipo</TableCell>
                                                <TableCell>Telefono</TableCell>
                                                <TableCell>Cumpleaños</TableCell>
                                            </>
                                        }
                                        <TableCell>Acciones</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>

                                    {clientList.map(client => (
                                        <ClientRow client={client}/>
                                    ))}

                                </TableBody>
                            </Table>
                        </Stack>
                    </Paper>
			</Stack>
		</Stack>
	);
}