import { useState, useEffect } from 'react';
import {
	Divider, Input , Paper, Stack, Typography, Button, List, ListItem, IconButton, Tooltip
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useAppContext } from '../../../context/context';

function NoteRow(props) {
	const { note, deleteNote } = props;

	function handleDelete() {
		deleteNote(note);
	}

	return (
		<ListItem>
			<Stack direction='row' justifyContent='space-between'
				sx={{width: '100%'}}
			>
				<Typography component="div"
					sx={{width: '80%'}}
				>
					{note}
				</Typography>

				<Divider orientation="vertical" flexItem sx={{mx: 1}}/>

				<IconButton onClick={handleDelete}><Tooltip title='Eliminar nota'><DeleteForeverIcon fontSize="small"/></Tooltip></IconButton>
			</Stack>
		</ListItem>
	);
}

export default function Notas(props) {
	const { note, emitView, addNote, deleteNote } = useAppContext();

	const [messageNote, setMessageNote] = useState([]);
	const [newNote, setNewNote] = useState('');
	const [needSave, setNeedSave] = useState(false);

	function handleChangeNote(newNoteMessage) {
		setNeedSave(true);
		setNewNote(newNoteMessage);
	}

	function submitChanges() {
		addNote(newNote);
		setNewNote('');
		setNeedSave(false);
	}

	useEffect(() => {
		let value = '';
		console.log(note);

		if (!!note?.note) {
			value = note?.note?.notes ?? [];

			console.log(value);
		}

		setMessageNote(value);
	}, [note]);

	return (
		<Paper sx={{padding: 1}}>
			<Stack>
				<Stack direction='row' justifyContent='space-between'>
					<Typography variant="h6" component="div">
						Notas del local
					</Typography>
					
					
				</Stack>
				<Stack direction='row' justifyContent='space-between'>
					<Input
						value={newNote}
						onChange={(ev) => handleChangeNote(ev.target.value)}
						sx={{width:'60%'}}
					/>

					<Button
							variant="contained"
							disabled={!needSave}
							onClick={submitChanges}
						>
						Agregar nota
					</Button>
				</Stack>

				<Divider orientation="horizontal" flexItem sx={{my: 1}}/>
			
				<List
					sx={{
						width: '100%',
						position: 'relative',
						overflow: 'auto',
						height: 130,
						maxHeight: 130,
					}}
				>
					{messageNote.map((note, i) => (
						<NoteRow note={note} key={i} deleteNote={deleteNote}/>
					))}
				</List>
			</Stack>
		</Paper>
	);
}